import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const AutocompleteInput = ({ classes, ...props }) => {
  return <TextField className={classes.textInput} variant="outlined" {...props} />;
};

AutocompleteInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AutocompleteInput;
