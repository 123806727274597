import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AVATAR_BLUE_GREY, AVATAR_PURPLE, AVATAR_RED, AVATAR_TEAL, HC_GREEN } from 'lib/constants';
import styles from './styles';

import useLiveUsers from '../../../hooks/useLiveUsers';

const getInitials = (name) =>
  name
    .split(' ')
    .map((word) => word[0]?.toUpperCase())
    .join('');

const bgColors = [AVATAR_BLUE_GREY, AVATAR_PURPLE, AVATAR_RED, AVATAR_TEAL, HC_GREEN];

const PresenceIndicator = ({ channelName }) => {
  const [expanded, setExpanded] = useState(false);
  const liveUsers = useLiveUsers(channelName);
  const maxAvatars = 3;

  return (
    <Container sx={styles.avatarGroupContainer}>
      <AvatarGroup
        max={expanded ? liveUsers.length + 1 : maxAvatars}
        renderSurplus={(surplus) => (
          <Avatar
            alt="Expand"
            onClick={() => setExpanded((prev) => !prev)}
            sx={styles.avatarCollapse}
          >
            +{surplus.toString()[0]}
          </Avatar>
        )}
      >
        {liveUsers.map(({ id, info: { name } }, index) => {
          const bgColor = bgColors[index % bgColors.length];
          return (
            <Tooltip key={id} title={name} arrow>
              <Avatar alt={name} sx={{ bgcolor: bgColor }}>
                {getInitials(name)}
              </Avatar>
            </Tooltip>
          );
        })}
        {expanded ? (
          <Avatar
            alt="Collapse"
            onClick={() => setExpanded((prev) => !prev)}
            sx={styles.avatarCollapse}
          >
            <KeyboardArrowRightIcon />
          </Avatar>
        ) : null}
      </AvatarGroup>
    </Container>
  );
};

PresenceIndicator.propTypes = {
  channelName: PropTypes.string.isRequired,
};

export default PresenceIndicator;
