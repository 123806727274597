// Dependencies
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';

import { CircularProgress, Container, Typography } from '@mui/material';

import { fetchQuarter } from 'redux/menugrid/quarter/actions';

import { FeatureFlag } from 'components/shared';
import PresenceIndicator from 'components/shared/Presence/PresenceIndicator';

// Components
import MenusApp from '../MenusApp';
import MenuPage from '../MenuPage';
import Header from '../Header';

const MenugridApp = ({ classes }) => {
  const standardMenus = useSelector((state) => state.menugrid.menus.menusWithPlaceholders);
  const standardStagingGroup = useSelector(
    (state) => state.menugrid.menus.stagingGroupWithPlaceholders
  );
  const { currentTime, fetching, error } = useSelector((state) => state.menugrid.quarter);

  const dispatch = useDispatch();

  const displayGroups = ['core', 'family', 'tempo'];

  useEffect(() => {
    dispatch(fetchQuarter(currentTime));
  }, [dispatch, currentTime]);

  if (fetching) {
    return (
      <Container className={classes.loading}>
        <CircularProgress size={200} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={classes.loading}>
        <Typography color="primary">Error: Quarter not found</Typography>
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      <FeatureFlag flag="user_presence_indicator_testing">
        <PresenceIndicator channelName="menugrid" />
      </FeatureFlag>
      <Header />
      <Routes>
        <Route path="*" element={<Navigate to="core" />} />
        {displayGroups.map((group) => {
          return (
            <Route
              key={group}
              path={`${group}`}
              element={
                <MenusApp
                  menus={standardMenus}
                  menuType="standard"
                  displayGroup={group}
                  stagingGroup={standardStagingGroup}
                />
              }
            />
          );
        })}
        {displayGroups.map((group) => {
          return (
            <Route
              key={`${group}-menu-page`}
              path={`${group}/:menuId`}
              element={<MenuPage displayGroup={group} />}
            />
          );
        })}
      </Routes>
    </div>
  );
};

MenugridApp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MenugridApp;
