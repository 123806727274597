import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const FAMILY = 'Family';

const BasicInfo = ({ classes, formState, handleFormState, mealPlanId }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const handleAutoAssign = () => {
    const newVal = !formState.autoAssign;
    handleFormState({ target: { name: 'autoAssign', value: newVal } });
  };

  const selectChefs = useMemo(() => {
    return formOptions.chefs.map((chef) => ({ label: chef.name, value: chef.id }));
  }, [formOptions.chefs]);

  const selectMealPlans = useMemo(() => {
    return formOptions.mealPlans.map((plan) => ({ label: plan.name, value: plan.id }));
  }, [formOptions.mealPlans]);

  const selectStatuses = useMemo(() => {
    return formOptions.statuses.map((status) => ({ label: status, value: status }));
  }, [formOptions.statuses]);

  const selectMealLabels = useMemo(() => {
    const currentMealPlan = selectMealPlans.find((plan) => plan.value === mealPlanId);
    const isFamilyPlan = currentMealPlan?.label === FAMILY;
    let formOptionsMealLabels = formOptions.mealLabels;

    if (isFamilyPlan) {
      formOptionsMealLabels = formOptionsMealLabels.filter((label) => label !== FAMILY);
    }

    return formOptionsMealLabels.map((label) => ({ label, value: label }));
  }, [formOptions.mealLabels, mealPlanId, selectMealPlans]);

  const selectMealBadges = useMemo(() => {
    return formOptions.mealBadges.map((badge) => ({ label: badge.name, value: badge.id }));
  }, [formOptions.mealBadges]);

  return (
    <Paper className={classes.paper}>
      <SectionHeader text="Basic Info" />
      <TextBox
        fullWidth
        label="Title"
        name="title"
        onChange={handleFormState}
        required
        value={formState.title}
      />
      <TextBox
        fullWidth
        label="Subtitle"
        name="subtitle"
        onChange={handleFormState}
        value={formState.subtitle}
      />
      <TextBox
        fullWidth
        label="Nutritional Facts Panel Title"
        name="nfpTitle"
        onChange={handleFormState}
        value={formState.nfpTitle}
      />

      <Dropdown
        label="Meal Plan"
        name="mealPlanId"
        value={formState.mealPlanId}
        required
        options={selectMealPlans}
        onChange={handleFormState}
      />
      <TextBox
        fullWidth
        label="Price"
        name="price"
        onChange={handleFormState}
        required
        value={formState.price}
      />
      <TextBox
        fullWidth
        label="Category"
        name="category"
        onChange={handleFormState}
        required
        value={formState.category}
      />
      <TextBox
        fullWidth
        label="Description"
        multiline
        name="description"
        onChange={handleFormState}
        value={formState.description}
      />
      <Dropdown
        label="Chef"
        name="chefId"
        required
        value={formState.chefId}
        options={selectChefs}
        onChange={handleFormState}
      />
      <div className={classes.row}>
        <Checkbox
          className={classes.checkbox}
          checked={formState.autoAssign}
          disableRipple
          name="autoAssign"
          onChange={handleAutoAssign}
        />
        <span className={classes.label}>Auto Assign</span>
      </div>
      <Dropdown
        label="Status"
        name="status"
        required
        value={formState.status}
        options={selectStatuses}
        onChange={handleFormState}
      />
      <Dropdown
        label="Meal label"
        name="mealLabel"
        value={formState.mealLabel}
        options={selectMealLabels}
        onChange={handleFormState}
      />
      <Dropdown
        label="Meal badge"
        name="mealBadgeId"
        value={formState.mealBadgeId}
        options={selectMealBadges}
        onChange={handleFormState}
      />
      <TextBox
        fullWidth
        label="Culinary Version Notes"
        multiline
        name="culinaryVersionNotes"
        onChange={handleFormState}
        value={formState.culinaryVersionNotes}
      />
    </Paper>
  );
};

BasicInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  mealPlanId: PropTypes.number.isRequired,
};

export default BasicInfo;
