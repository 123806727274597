// Dependencies
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { SessionContext } from 'contexts/sessionContext';
import { FeatureFlag } from 'components/shared';

import { formOptionsShape, formStateShape } from 'lib/helpers/incidentManager/propShapes';
import IngredientCheckboxes from './IngredientCheckboxes';
import Input from '../../Input';
import AutocompleteInput from '../../AutocompleteInput';

const DeliveryIncidentFields = ({
  classes,
  formOptions,
  formState,
  setFormState,
  handleFormState,
  incidentable,
}) => {
  const [mealSelectionRequired, updateMealSelectionRequired] = useState(false);
  const [ingredientSelectionRequired, updateIngredientSelectionRequired] = useState(false);
  const { featureFlags } = useContext(SessionContext);
  const betaTestingEnabled = featureFlags.cs_beta_testing;

  const renderErrorTypes = () => {
    return formOptions.errorTypes.map((errorType) => {
      return (
        <MenuItem key={errorType.id} value={errorType.id}>
          {errorType.name}
        </MenuItem>
      );
    });
  };
  const errorTypeOptions = useMemo(
    () =>
      formOptions.errorTypes.map((errorType) => ({
        id: errorType.id,
        label: errorType.name,
      })),
    [formOptions.errorTypes]
  );

  const selectedErrorType = useMemo(
    () => errorTypeOptions.find((errorType) => errorType.id === formState.causeId) || null,
    [errorTypeOptions, formState.causeId]
  );

  const handleErrorTypeChange = (event, newValue) => {
    if (betaTestingEnabled) {
      handleFormState({ target: { name: 'causeId', value: newValue?.id } });

      if (newValue === null) {
        updateMealSelectionRequired(false);
        updateIngredientSelectionRequired(false);
      } else {
        const value = newValue.id;
        const errorTypeSelection = formOptions.errorTypes.find((cause) => cause.id === value);

        updateMealSelectionRequired(errorTypeSelection.mealSelectionRequired);
        updateIngredientSelectionRequired(errorTypeSelection.ingredientSelectionRequired);
      }
    } else {
      handleFormState(event);

      const { value } = event.target;
      if (value === '') {
        updateMealSelectionRequired(false);
        updateIngredientSelectionRequired(false);
      } else {
        const errorTypeSelection = formOptions.errorTypes.find((cause) => cause.id === value);

        updateMealSelectionRequired(errorTypeSelection.mealSelectionRequired);
        updateIngredientSelectionRequired(errorTypeSelection.ingredientSelectionRequired);
      }
    }
  };

  const filteredSubcategories = () => {
    return formOptions.deliveryRemediationCategories.filter((rem) =>
      rem.allowedErrorTypes.includes(formState.causeId)
    );
  };

  const renderFilteredSubcategories = () => {
    if (!formState.causeId) {
      return [];
    }

    return filteredSubcategories().map((category) => {
      return (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    switch (filteredSubcategories().length) {
      case 1:
        if (formState.remediationCategoryId !== filteredSubcategories()[0].id) {
          setFormState({ ...formState, remediationCategoryId: filteredSubcategories()[0].id });
        }
        break;
      case 0:
        if (formState.remediationCategoryId !== '') {
          setFormState({ ...formState, remediationCategoryId: '' });
        }
        break;
      default:
        break;
    }
  });

  const renderMealInput = () => {
    return (
      <Input
        label="Meal"
        name="mealId"
        onChange={handleFormState}
        select
        value={formState.mealId}
        required={mealSelectionRequired || ingredientSelectionRequired}
      >
        <MenuItem value="">Entire basket affected</MenuItem>
        {incidentable.mealSelections.map((selection) => {
          return (
            <MenuItem key={selection.mealId} value={selection.mealId}>
              {selection.title}
            </MenuItem>
          );
        })}
      </Input>
    );
  };

  const renderIngredientsInput = () => {
    const meal = incidentable.mealSelections.find(
      (selection) => selection.mealId === formState.mealId
    );

    return (
      <IngredientCheckboxes
        classes={classes}
        handleFormState={handleFormState}
        ingredientAssignments={meal.ingredients}
        required={ingredientSelectionRequired}
        otherIngredient={incidentable.otherIngredient}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FeatureFlag flag="cs_beta_testing">
            <Autocomplete
              disablePortal
              autoHighlight
              options={errorTypeOptions}
              value={selectedErrorType}
              onChange={handleErrorTypeChange}
              slotProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
              renderInput={(params) => (
                <AutocompleteInput {...params} label="Error Type" name="causeId" required />
              )}
            />
          </FeatureFlag>
          <FeatureFlag flag="cs_beta_testing" whenOff>
            <Input
              label="Error Type"
              name="causeId"
              onChange={handleErrorTypeChange}
              required
              select
              value={formState.causeId || ''}
            >
              <MenuItem value="">Error Type</MenuItem>
              {renderErrorTypes()}
            </Input>
          </FeatureFlag>
        </Grid>
        <Grid item xs={6}>
          <Input
            label="Subcategory"
            name="remediationCategoryId"
            onChange={handleFormState}
            required
            select
            value={formState.remediationCategoryId}
          >
            <MenuItem value="">Subcategory</MenuItem>
            {renderFilteredSubcategories()}
          </Input>
        </Grid>
      </Grid>

      {renderMealInput()}
      {formState.mealId && renderIngredientsInput()}

      <Input
        label="Description"
        multiline
        name="description"
        onChange={handleFormState}
        required
        value={formState.description}
      />
    </>
  );
};

DeliveryIncidentFields.propTypes = {
  classes: PropTypes.object.isRequired,
  formOptions: formOptionsShape.isRequired,
  formState: formStateShape.isRequired,
  setFormState: PropTypes.func.isRequired,
  handleFormState: PropTypes.func.isRequired,
  incidentable: PropTypes.object.isRequired,
};

export default DeliveryIncidentFields;
