import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { startCase, isEmpty } from 'lodash';

import { Button, Grid, Paper, Stack, Chip } from '@mui/material';
import { FeatureFlag } from 'components/shared';
import { ASSIGNABLE_EVENTS } from 'lib/constants';
import { postCompoundIngredientState } from 'redux/compoundIngredientRecipeForm/actions';

import UpdateStateDialog from './UpdateStateDialog/UpdateStateDialog';
import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const BasicAttributes = ({ classes, formState, handleFormState, dirty }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);
  const [openDialog, setOpenDialog] = useState(false);
  const [eventType, setEventType] = useState('');

  const dispatch = useDispatch();

  const selectStates = useMemo(() => {
    return formOptions.states.map((state) => ({
      label: state,
      value: state,
    }));
  }, [formOptions.states]);

  const selectIngredientClassifications = useMemo(() => {
    return formOptions.ingredientClassifications.map((classification) => ({
      label: classification.path,
      value: classification.id,
    }));
  }, [formOptions.ingredientClassifications]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEventType('');
  };

  const postStateAction = (eventParam) => {
    dispatch(
      postCompoundIngredientState({
        compoundIngredientId: formState.id,
        eventType: eventParam,
      })
    );
  };

  const handleStateAction = (event) => {
    if (ASSIGNABLE_EVENTS.includes(event) && !isEmpty(formState.otherAssignableVersions)) {
      setOpenDialog(true);
      setEventType(event);
    } else {
      postStateAction(event);
    }
  };

  return (
    <Paper className={classes.paperWithBottomMargin}>
      <SectionHeader text="Basic Attributes" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextBox
            fullWidth
            label="Name"
            name="name"
            onChange={handleFormState}
            required
            value={formState.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            label="Ingredient Classification"
            name="ingredientClassificationId"
            onChange={handleFormState}
            options={selectIngredientClassifications}
            value={formState.ingredientClassificationId}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <FeatureFlag flag="enable_compound_ingredient_management">
              <Grid item xs={12}>
                <span className={classes.labelText}>Current State</span>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={0.5}>
                  {formOptions.states.map((state) => {
                    return (
                      <Chip
                        color={formState.state === state ? 'primary' : 'default'}
                        label={`${startCase(state)}`}
                        key={state}
                      />
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <span className={classes.labelText}>Actions</span>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={0.5}>
                  {formState.events?.map((event) => {
                    return (
                      <Button
                        onClick={() => handleStateAction(event)}
                        variant="outlined"
                        key={event}
                        disabled={dirty}
                      >
                        {startCase(event)}
                      </Button>
                    );
                  })}
                </Stack>
              </Grid>
              {/* Render only when editing an exisiting CI */}
              {formState.id && (
                <UpdateStateDialog
                  open={openDialog}
                  onClose={() => handleCloseDialog()}
                  compoundIngredientDisplayName={formState.displayName}
                  compoundIngredientId={formState.id}
                  compoundIngredientState={formState.state}
                  postStateAction={postStateAction}
                  eventType={eventType}
                  otherAssignableVersions={formState.otherAssignableVersions || []}
                />
              )}
            </FeatureFlag>
            <FeatureFlag flag="enable_compound_ingredient_management" whenOff>
              <Dropdown
                label="State"
                name="state"
                onChange={handleFormState}
                options={selectStates}
                required
                value={formState.state}
              />
            </FeatureFlag>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BasicAttributes.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

BasicAttributes.defaultProps = {
  dirty: false,
};

export default BasicAttributes;
