import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Card from '@mui/material/Card';

import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';

const Channel = ({ classes, formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const [gtinAssignments, setGtinAssignments] = useState(meal.gtinAssignments);
  const [showRetailDropdown, setShowRetailDropdown] = useState(false);
  const [showGtinAssignmentDropdown, setShowGtinAssignmentDropdown] = useState(false);

  useEffect(() => {
    if (formState?.channelId === 2) {
      setShowRetailDropdown(true);
      if (showRetailDropdown && meal.producedByThirdParty) {
        setShowGtinAssignmentDropdown(true);
      }
    } else {
      setShowRetailDropdown(false);
      setShowGtinAssignmentDropdown(false);
    }
  }, [formState.channelId, showRetailDropdown, meal.producedByThirdParty]);

  /**
   * Ceates an object for GTIN Assignments and updates the form state 'gtinAssignments'
   * value with the current state of GTIN Assignments.
   *
   * The ingredientAssignmentId is the ingredient assignment ID assigned to the GTIN.
   * The object format is as follows:
   * {
   *  4453: {
   *    gtinId: 4453,
   *    ingredientAssignmentId: 115612
   *  }
   * }
   *
   * @function handleGtinAssignmentChange
   */
  const handleGtinAssignmentChange = (event) => {
    const newState = {
      ...gtinAssignments,
      [event.target.name]: {
        gtinId: event.target.name,
        ingredientAssignmentId: event.target.value,
      },
    };
    setGtinAssignments(newState);
    handleFormState({ target: { name: 'gtinAssignments', value: newState } });
  };

  const selectChannels = useMemo(() => {
    return formOptions.channels.map((channel) => ({ label: channel.name, value: channel.id }));
  }, [formOptions.channels]);

  const selectIngredientAssignments = useMemo(() => {
    return meal.ingredientAssignments.map((ingredientAssignment) => ({
      label: ingredientAssignment.name,
      value: ingredientAssignment.id,
      key: ingredientAssignment.id,
    }));
  }, [meal.ingredientAssignments]);

  const renderGtinAssignmentsDropdown = () => {
    const filteredGtinAssignments = formOptions.gtinAssignments.filter(
      (gtinAssignment) => gtinAssignment.meal_id === meal.id
    );

    return filteredGtinAssignments.map((gtinAssignment) => {
      const gtinAssignmentValue = formState.gtinAssignments[gtinAssignment.id]
        ?.ingredientAssignmentId
        ? formState.gtinAssignments[gtinAssignment.id].ingredientAssignmentId
        : undefined;

      return (
        <Dropdown
          key={gtinAssignment.gtin}
          label={`GTIN - ${gtinAssignment.gtin}`}
          name={`${gtinAssignment.id}`}
          onChange={handleGtinAssignmentChange}
          value={gtinAssignmentValue}
          options={selectIngredientAssignments}
          required
        />
      );
    });
  };

  return (
    <Card className={classes.channelCard}>
      <SectionHeader text="Channel" />
      <Dropdown
        label="Channel"
        name="channelId"
        value={formState.channelId}
        options={selectChannels}
        onChange={handleFormState}
        required
      />
      {showGtinAssignmentDropdown && <>{renderGtinAssignmentsDropdown()}</>}
    </Card>
  );
};

Channel.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default Channel;
